import React from "react"
import { Link, graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Hero from "../components/hero/hero"
// import ScrollAnimation from 'react-animate-on-scroll';
// import { Container } from "reactstrap"
// import MySwiper from "../components/swiper/swiper"
// import SampleForm from "../components/form/form"
// import { LazyLoadImage } from "react-lazy-load-image-component"
// import "react-lazy-load-image-component/src/effects/blur.css"
import "animate.css/animate.min.css"
import "./index.css"
// import MySwiper from "../components/swiper/swiper"

class IndexPage extends React.Component {
  render() {
    const siteTitle = this.props.data.site.siteMetadata.title
    const siteDescription = this.props.data.site.siteMetadata.description
    const posts = this.props.data.allSanityPost.edges
    // console.log(posts)
    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO
          title="Custom Home Design and Remodel in Colorado"
          description="JKA Design, LLC is an architectural firm in Colorado that handles house designs, remodels, and building permits. Contact them for custom solutions."
        />
        <Hero />
        <div className="about-section">
          <div className="row mx-0">
            <div className="col-md-6 about-content-container">
              <div className="about-content mx-auto px-4">
                <h2 className="mb-0 alt-heading">ABOUT</h2>
                <h2 className="about-heading mb-4 mt-0">Who is JKA Design?</h2>
                <p>
                  JKA Design LLC is passionate about helping Colorado
                  home-owners improve their living space and achieving a higher
                  quality lifestyle; one where their house is comfortable,
                  efficient, and actually serves them.
                </p>

                <p>
                  With an award-winning architect, Jeff Abrams, at the helm - we
                  provide clever and effective solutions for:
                </p>
                <ul className="mb-5">
                  <li>Custom Home Designs</li>
                  <li>Residential Renovations </li>
                  <li>Home Additions and Remodels</li>
                  <li>Special Structures and Complex Projects</li>
                  <li>Luxury Homes On Difficult Sites</li>
                </ul>

                <Link className="btn-global offset2" to="/about-us">
                  READ More
                </Link>
              </div>
            </div>
            <div className="col-md-6 about-pic px-0" />
          </div>
        </div>

        <div className="review-snippet padding-50 text-center text-white">
          <div className="container">
            <p>
              <i className="fa fa-quote-left mr-3" />
              <em>
                “Jeff Abrams of JKA Design was wonderful to work with. He
                patiently guided us through exploration of many different design
                options for remodeling our home to add an additional bedroom,
                new bathroom and expansion of our master bedroom suite. He
                helped us align our objectives for the home addition with our
                available budget. His experience and vision were invaluable.
                Additionally, Jeff is just great person. We enjoyed learning
                from him and just spending time with him.”
              </em>
              <i className="fa fa-quote-right ml-3" />
            </p>
          </div>

          <em>— Bob and Betsy M., Homeowners</em>
        </div>

        <div className="project-section">
          <div className="row text-white mx-0">
            <div className="col-md-12 text-dark proj-blurb padding-100 text-center">
              <div className="proj-blurb-inner mx-auto my-0 px-4">
                <h2 className="mb-0 alt-heading">PROJECTS</h2>
                <h2 className="proj-heading mb-4 mt-0">
                  What We've Done So Far
                </h2>
                <p>
                  JKA Design, LLC works with current and future homeowners in
                  Colorado (Boulder, Denver, and Westminster) to custom design
                  their homes.
                </p>
                <p>
                  We are your reliable partner in residential renovations,
                  remodels, redesigns, and designing a house from the ground up.
                  We can also assist in processing residential building permits.
                </p>
                <p>
                  If you are in need of a Colorado architect, general
                  contractor, and project manager - JKA Design is here for you.
                  Let us design your dream living space and get the project
                  started.
                </p>
                <p className="pb-4">
                  See the various projects we have completed over the years to
                  get an idea of how we can assist with your project. Or,
                  connect with us directly for even more personalized service!
                </p>
                <Link className="btn-global offset2" to="/projects">
                  VIEW MORE PROJECTS
                </Link>
              </div>
            </div>
            <div className="col-md-2 px-0 proj-1">
              <Link
                to="/portfolio-item/contemporary-entry-and-master-addition"
                className="proj-icon"
              />
              <div className="proj-content p-4">
                <h5 className="mb-3 proj-heading-1 text-shadow">
                  CONTEMPORARY ENTRY AND MASTER ADDITION
                </h5>
                <h6 className="mt-0 proj-heading-2 text-shadow">
                  Entry Court Remodel / Master Addition – Boulder, CO
                </h6>
              </div>
              <div className="proj-1-img" />
              <div className="proj-overlay" />
            </div>
            <div className="col-md-2 px-0 proj-2">
              <Link
                to="/portfolio-item/french-country-estate"
                className="proj-icon"
              />
              <div className="proj-content p-4">
                <h5 className="mb-3 proj-heading-1 text-shadow">
                  FRENCH COUNTRY ESTATE
                </h5>
                <h6 className="mt-0 proj-heading-2 text-shadow">
                  French-inspired Luxury Home – Westminster, CO
                </h6>
              </div>
              <div className="proj-2-img" />
              <div className="proj-overlay" />
            </div>
            <div className="col-md-2 px-0 proj-3">
              <Link
                to="/portfolio-item/mountain-modern"
                className="proj-icon"
              />
              <div className="proj-content p-4">
                <h5 className="mb-3 proj-heading-1 text-shadow">
                  MOUNTAIN MODERN
                </h5>
                <h6 className="mt-0 proj-heading-2 text-shadow">
                  A Modern Home Nestled in a Rugged Site in Lyons, CO
                </h6>
              </div>
              <div className="proj-3-img" />
              <div className="proj-overlay" />
            </div>
            <div className="col-md-2 px-0 proj-4">
              <Link
                to="/portfolio-item/european-style-estate"
                className="proj-icon"
              />
              <div className="proj-content p-4">
                <h5 className="mb-3 proj-heading-1 text-shadow">
                  EUROPEAN STYLE ESTATE
                </h5>
                <h6 className="mt-0 proj-heading-2 text-shadow">
                  Eurostyle Luxury Home – Arvada, CO
                </h6>
              </div>
              <div className="proj-4-img" />
              <div className="proj-overlay" />
            </div>
            <div className="col-md-2 px-0 proj-5">
              <Link
                to="/portfolio-item/foothills-modern"
                className="proj-icon"
              />
              <div className="proj-content p-4">
                <h5 className="mb-3 proj-heading-1 text-shadow">
                  FOOTHILLS MODERN
                </h5>
                <h6 className="mt-0 proj-heading-2 text-shadow">
                  Custom Residence – North Boulder, CO
                </h6>
              </div>
              <div className="proj-5-img" />
              <div className="proj-overlay" />
            </div>
            <div className="col-md-2 px-0 proj-6">
              <Link
                to="/portfolio-item/whole-house-remodel-addition"
                className="proj-icon"
              />
              <div className="proj-content p-4">
                <h5 className="mb-3 proj-heading-1 text-shadow">
                  WHOLE HOUSE REMODEL/ ADDITION
                </h5>
                <h6 className="mt-0 proj-heading-2 text-shadow">
                  French Estate Pop-top Addition and Remodel – Golden, CO
                </h6>
              </div>
              <div className="proj-6-img" />
              <div className="proj-overlay" />
            </div>
          </div>
        </div>

        <div className="review-snippet padding-50 text-center text-white">
          <div className="container">
            <p>
              <i className="fa fa-quote-left mr-3" />
              <em>
                We live in a home designed by Jeff Abrams and for more than 20
                years have been delighted by his work. Our home is both
                beautiful and functional as a result of Jeff’s thoughtful
                creativity. Jeff has also advised us over the years on potential
                remodel and improvement projects. We value and appreciate his
                insights and suggestions. We highly recommend Jeff Abrams as a
                home design and remodel professional.
              </em>
              <i className="fa fa-quote-right ml-3" />
            </p>
          </div>

          <em>— Gordon R., Homeowner</em>
        </div>

        {/* <div className="review-section padding-100"> */}
        {/* <div className="container">
            <h2 className="text-center mb-0 alt-heading">TESTIMONIALS</h2>
            <h3 className="svc-heading mt-0 mb-5 text-center">
              What People Say About Us
            </h3>
            <div className="col-md-8 m-auto">
              <div
                className="carousel slide"
                data-ride="carousel"
                data-interval={10000}
                id="carousel-t"
              >
                <div className="carousel-inner" role="listbox">
                  <div className="carousel-item active">
                    <div className="col-9 text-center mx-auto testimonial-content">
                      <p className="text-center">
                        <em>
                          <i className="fa fa-quote-left mr-3" /> Lorem ipsum
                          dolor sit amet, nec cu omnium ponderum instructior,
                          eligendi gubergren cotidieque te eam. Sed ceteros
                          salutatus definiebas eu, ut modo argumentum reprimique
                          quo. Per te convenire facilisis. Eu vel noster
                          scaevola molestiae. Lorem ipsum dolor sit amet, nec cu
                          omnium ponderum instructior, eligendi gubergren
                          cotidieque te eam. Sed ceteros salutatus definiebas
                          eu, ut modo argumentum reprimique quo. Per te
                          convenire facilisis. Eu vel noster scaevola molestiae.{" "}
                          <i className="fa fa-quote-right ml-3" />
                        </em>
                        <br />
                      </p>
                      <img
                        className="rounded-circle mt-3 mb-0"
                        src="/assets/img/test-man.jpg"
                        width={50}
                        alt="client"
                      />
                      <h5 className="mt-3 mb-2">JOHN DOE</h5>
                      <h6 className="mt-0">Client</h6>
                    </div>
                  </div>
                </div>
                <div>
                  <a
                    className="carousel-control-prev"
                    href="#carousel-t"
                    role="button"
                    data-slide="prev"
                  >
                    <i className="fa fa-chevron-circle-left d-flex d-lg-flex justify-content-center align-items-center" />
                    <span className="sr-only">Previous</span>
                  </a>
                  <a
                    className="carousel-control-next"
                    href="#carousel-t"
                    role="button"
                    data-slide="next"
                  >
                    <i className="fa fa-chevron-circle-right d-flex d-lg-flex justify-content-center align-items-center" />
                    <span className="sr-only">Next</span>
                  </a>
                </div>
                <ol className="carousel-indicators">
                  <li
                    data-target="#carousel-t"
                    data-slide-to={0}
                    className="active"
                  />
                </ol>
              </div>
            </div>
          </div> */}
        {/* <h2 className="text-center mb-0 alt-heading">TESTIMONIALS</h2>
            <h3 className="svc-heading mt-0 mb-5 text-center">
              What People Say About Us
            </h3>
            <div class="container review-container">
            <div className="swiper-prev" id="review-prev">
              <i className="fa fa-angle-left" />
            </div>
            <div className="swiper-next" id="review-next">
              <i className="fa fa-angle-right" />
            </div>
            <MySwiper slidesperview={1}>
                  <div className="row justify-content-center">
                    <div className="col-5  mx-auto">
                      <div className="pt-3">
                        <div className="text-center testimonial-content">
                          <div className="review-statement" id="carousel-t">
                            <p className="text-center pt-3"><i className="fa fa-quote-left mr-3" />
                            <em> Lorem ipsum dolor sit amet, nec cu omnium ponderum instructior, eligendi gubergren cotidieque te eam. Sed ceteros salutatus definiebas eu, ut modo argumentum reprimique quo. Per te convenire facilisis. Eu vel noster scaevola molestiae. Lorem ipsum dolor sit amet, nec cu omnium ponderum instructior, eligendi gubergren cotidieque te eam. Sed ceteros salutatus definiebas eu, ut modo argumentum reprimique quo. Per te convenire facilisis. Eu vel noster scaevola molestiae.</em> 
                              <i className="fa fa-quote-right ml-3" />
                            </p>
                          </div>
                          <div className="pt-3">
                          <img
                            className="rounded-circle mt-3 mb-0"
                            src="/assets/img/test-man.jpg"
                            width={50}
                            alt="client"
                          />
                            <h5 className="mb-0 mt-2">Melissa K.</h5>
                            <span className="text-muted">
                              Colorado Springs, CO
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row justify-content-center">
                    <div className="col-5  mx-auto">
                      <div className="pt-3">
                        <div className="text-center testimonial-content">
                          <div className="review-statement" id="carousel-t">
                            <p className="text-center pt-3"><i className="fa fa-quote-left mr-3" />
                            <em> Lorem ipsum dolor sit amet, nec cu omnium ponderum instructior, eligendi gubergren cotidieque te eam. Sed ceteros salutatus definiebas eu, ut modo argumentum reprimique quo. Per te convenire facilisis. Eu vel noster scaevola molestiae. Lorem ipsum dolor sit amet, nec cu omnium ponderum instructior, eligendi gubergren cotidieque te eam. Sed ceteros salutatus definiebas eu, ut modo argumentum reprimique quo. Per te convenire facilisis. Eu vel noster scaevola molestiae.</em> 
                              <i className="fa fa-quote-right ml-3" />
                            </p>
                          </div>
                          <div className="pt-3">
                          <img
                            className="rounded-circle mt-3 mb-0"
                            src="/assets/img/test-man.jpg"
                            width={50}
                            alt="client"
                          />
                            <h5 className="mb-0 mt-2">Melissa K.</h5>
                            <span className="text-muted">
                              Colorado Springs, CO
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </MySwiper>
            </div>
        </div> */}

        <div className="svc-section off-white">
          <h2 className="text-center mb-0 alt-heading">SERVICES</h2>
          <h2 className="svc-heading mt-0 mb-5 text-center">What We Do</h2>
          <div className="container-fluid">
            <div className="row">
              <div
                data-aos="slide-right"
                data-aos-duration={2500}
                className="col-md-6 svc-img-1"
              />

              <div className="col-md-6 padding-100">
                <div className="px-4 py-5 svc-box mx-auto">
                  <h3 className="mt-0">Residential Design & Architecture</h3>
                  <p className="mb-5">
                    We help our client navigate the complex series of processes
                    in order to build their desired custom home or remodel. We
                    work to consult on the desired outcome and then we work to
                    facilitate the team of professionals to make our client's
                    dream a reality.
                  </p>
                  <Link className="btn-global offset2" to="/services">
                    Learn More
                  </Link>
                </div>
              </div>
            </div>
            <div className="row flex-column-reverse flex-md-row">
              <div className="col-md-6 padding-100">
                <div className="px-4 py-5 svc-box mx-auto">
                  <h3 className="mt-0">Documentation For Building Permits</h3>
                  <p className="mb-5">
                    In the end you just need a permit. We are here to help
                    facilitate the municipal demands and hoops you'll need to
                    jump through in order to make your project a reality.
                  </p>
                  <Link className="btn-global offset2" to="/services">
                    Learn More
                  </Link>
                </div>
              </div>
              <div
                data-aos="slide-left"
                data-aos-duration={2500}
                className="col-md-6 svc-img-2"
              />
            </div>
            <div className="d-none row">
              <div
                data-aos="slide-right"
                data-aos-duration={2500}
                className="col-md-6 svc-img-3"
              />
              <div className="col-md-6 padding-100">
                <div className="px-4 py-5 svc-box mx-auto">
                  <h3 className="mt-2">DOCUMENTATION FOR BUILDING PERMITS</h3>
                  <p className="mb-5">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                    ullamco laboris nisi ut aliquip ex ea commodo consequat.
                  </p>
                  <Link className="btn-global" to="/">
                    Learn More
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="review-snippet padding-50 text-center text-white">
          <div className="container">
            <p>
              <i className="fa fa-quote-left mr-3" />
              <em>
                My wife and I asked Jeff Abrams to present ideas for the upgrade
                and improvements to the exterior of our 90 year old house. We
                met with Jeff and his assistant twice to discuss alternative
                possibilities, including adding architectural details to the
                exterior and redesigning a patio roof. Jeff and his assistant
                created several elevation drawings of the front of the house and
                the patio roof design, showing alternate detail additions. The
                CADD generated idea sketch drawings have been quite helpful in
                making our decisions with regard to choosing the detail
                additions that we will proceed with.
              </em>
              <i className="fa fa-quote-right ml-3" />
            </p>
          </div>

          <em>— Devin R., Homeowner</em>
        </div>

        <div className="blog-section padding-100 off-white">
          <h2 className="text-center mb-0 alt-heading">OUR BLOG</h2>
          <h2 className="svc-heading mt-0 mb-5 text-center">
            Latest from JKA Design
          </h2>
          <div class="container">
            <div class="row justify-content-around py-5">
              {posts.map(post => (
                <div class="col-md-4 mb-5" key={post.node.id}>
                  <div class="h-100 blog-inner shadow-sm">
                    <Link to={post.node.slug.current}>
                      <div
                        class="blog-image"
                        style={{
                          backgroundImage: `url(${
                            post.node.mainImage !== null
                              ? post.node.mainImage.asset.fluid.src
                              : "https://source.unsplash.com/user/joshhild/500x500"
                          })`,
                        }}
                      />
                    </Link>
                    <div class="blog-content py-3 px-4">
                      <Link to={post.node.slug.current}>
                        <h4>{post.node.title}</h4>
                      </Link>
                      <p class="pt-2 text-muted">{post.node.excerpt}</p>
                      <div class="blog-item-author-image d-flex align-items-center justify-content-start">
                        <span class="author-img mr-2">
                          <img
                            alt={post.node.authors[0].person.name}
                            class="rounded-circle"
                            src={
                              post.node.authors[0].person.image.asset.fluid.src
                            }
                            height="32"
                            width="32"
                          />
                        </span>
                        <div class="blog-item-authorname-date">
                          <span class="text-muted small d-block">
                            {post.node.authors[0].person.name}
                          </span>
                          <span class="text-muted small">
                            <i class="fa fa-calendar-o pr-1" />
                            {post.node.publishedAt}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
            <div class="text-center">
              <Link className="btn-global offset2" to="/blog">
                More from the Blog
              </Link>
            </div>
          </div>
        </div>
        <div className="review-snippet padding-50 text-center text-white">
          <div className="container">
            <p>
              <i className="fa fa-quote-left mr-3" />
              <em>
                I contacted Jeff after viewing his website. I liked his
                presentation on the site. It was more than photos of past
                projects; he included the necessary steps that needed to be
                taken to accomplish our goal. Jeff and Dillon have been terrific
                professionals to guide us through this process. Our project is a
                complicated one – with many twists and turns depending on Denver
                zoning guidelines. I feel as if Jeff and his team are concerned
                friends and although this is a relatively small project – Jeff
                is there for us. I obviously would recommend this company
                highly.
              </em>
              <i className="fa fa-quote-right ml-3" />
            </p>
          </div>

          <em>— Maureen P., Client</em>
        </div>
      </Layout>
    )
  }
}

export default IndexPage

export const indexPageQuery = graphql`
  query indexPageQuery {
    site {
      siteMetadata {
        title
        author
        description
      }
    }
    allSanityPost(sort: { order: DESC, fields: publishedAt }, limit: 3) {
      edges {
        node {
          id
          title
          publishedAt(formatString: "MMMM DD, YYYY")
          mainImage {
            asset {
              fluid {
                src
              }
            }
          }
          authors {
            person {
              id
              name
              image {
                asset {
                  id
                  fluid {
                    src
                  }
                }
              }
            }
          }
          excerpt
          slug {
            current
          }
          body
          categories {
            title
          }
        }
      }
      group(field: categories___title) {
        fieldValue
      }
    }
  }
`

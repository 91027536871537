import React from "react"
import { Link } from "gatsby"
import "./hero.css"

class Hero extends React.Component {
  render() {
    return (
      <div className="atf-section">
        <div className="slider text-center">
          <div className="brandstamp w-100">
            <div className="brandstamp-inner m-auto text-dark">
              <h1 className="mt-4 mb-0 text-shadow">
                Your Lifestyle, Our Creative and Authentic Design
                <br />
              </h1>
              <h3 className="mt-3 mb-5">
              Design Your Dream Home in Boulder and Denver, Colorado
                <br />
              </h3>
              <Link className="btn-global bs-offset2 main-cta" to="/#contact">
                Schedule a Low Committment Consultation
              </Link>
            </div>
          </div>
          <div className="slides slide1" />
          <div className="slides slide2" />
          <div className="slides slide3" />
        </div>
      </div>
    )
  }
}

export default Hero
